@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


.protecting {
    background: url("../public/dashgb.jpg");
    background-position: center;
    background-size: cover;
}

/* Preloader.css */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #121212; /* Background color for the preloader */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 8px solid #f3f3f3; /* Light gray border */
  border-top: 8px solid #1A2B6B; /* Blue border */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

svg {
  fill: currentColor;
  height: auto;
  max-width: 66vmin;
  transform-origin: center;
  width: 66%;
}

/* src/components/CryptoExchangeTicker.css */
.crypto-exchange-ticker {
  position: relative;
  bottom: 50px; /* Adjust for footer stacking */
  left: 0;
  width: 100%;
  color: #9DA4BB;
  padding: 10px 0;
  overflow: hidden;
  white-space: nowrap;
}

.ticker-track {
  display: flex;
  align-items: center;
  animation: scroll 40s linear infinite; /* Slower scroll for exchange names */
}

.exchange-item {
  display: flex;
  align-items: center;
  margin-right: 30px;
  font-family: Arial, sans-serif;
  white-space: nowrap;
  font-weight: bold;
}

.exchange-logo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Keyframes for scrolling effect */
@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

/* src/components/CryptoPriceFooter.css */
.crypto-price-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #121212;
  color: white;
  padding: 10px 0;
  overflow: hidden;
  z-index: 1000;
  white-space: nowrap;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}

.ticker-track {
  display: flex;
  animation: scroll 30s linear infinite;
}

.ticker-item {
  display: flex;
  align-items: center;
  margin-right: 30px;
  font-size: 14px;
  font-family: Arial, sans-serif;
  white-space: nowrap;
}

.ticker-item img {
  border-radius: 50%;
}

/* Keyframes for scrolling effect */
@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.first {
  opacity: 0; 
  animation: fadeInUp 1s ease-in-out 0s forwards;
  animation-delay: 0.5s;
}

.second {
  opacity: 0; 
  animation: fadeInUp 1s ease-in-out 0s forwards;
  animation-delay: 1s;
}

.third {
  opacity: 0;
  animation: fadeInUp 1s ease-in-out 0s forwards;
  animation-delay: 2s;

}

.fourth {
  opacity: 0;
  animation: fadeInUp 1s ease-in-out 0s forwards;
  animation-delay: 3s;
}

/* defines the animation */
@keyframes fadeInUp {
  from { 
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

html {
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
}

/* MegaMenu.css */
.menu-item:hover .sub-menu {
    display: block;
  }
  .sub-menu {
    display: none;
    position: absolute;
    /* Add styling for sub-menu position, background color, etc. */
  }

  .tabs {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 5px;
  }
  
  .tab-button {
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    padding: 10px 20px;
    cursor: pointer;
    margin: 0;
    border-radius: 10px;
    font-weight: bold;
    color: #333;
    transition: all 0.3s;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  }
  
  .tab-button:hover {
    background-color: #eaeaea;
  }
  
  .tab-button.active {
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  }